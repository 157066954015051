import React from 'react';
import { Box, Text, Heading, Divider, VStack } from '@chakra-ui/react';

const CessationSection = () => {
  return (
    
      <VStack align="start" spacing={4}>
        {/* Section 7: Cessation */}
        <Heading as="h2" size="lg" textAlign="center" mb={4}>
          7. Cessation
        </Heading>
        <Divider />

        {/* Subsection a: Termination of Treatment */}
        <Heading as="h3" size="md">
          a. Termination of Treatment
        </Heading>
        <Text>
          In certain circumstances, we may need to:
        </Text>
        <VStack align="start" pl={4} spacing={2}>
          <Text>- Transfer your care to another <strong>physician or medical provider</strong></Text>
          <Text>- Notify you that we will <strong>no longer provide treatment</strong></Text>
        </VStack>
        <Text>
          If this happens, we will provide you with <strong>30 days’ notice</strong> prior to the cessation of your membership.
        </Text>
        <Text>
          There are specific circumstances under which we may choose to <strong>immediately terminate</strong> this Agreement. These include, but are not limited to:
        </Text>

        {/* Termination Circumstances */}
        <VStack align="start" pl={4} spacing={2}>
          <Text>- Failure to pay <strong>Fees and charges</strong> when they are due</Text>
          <Text>- Failure to sign our <strong>Controlled Substances Agreement</strong>, Private Medicare Contract, or other required documentation</Text>
          <Text>- Failure or refusal to adhere to the <strong>recommended treatment plan</strong></Text>
          <Text>- Being <strong>disruptive, abusive</strong>, or presenting an emotional or physical danger to the wellbeing of staff or other patients</Text>
          <Text>- If <strong>OYASU’S PRACTICE discontinues operation</strong></Text>
        </VStack>

        <Divider my={4} />
        <Text fontWeight="bold" textAlign="center">
          These conditions ensure the safety, continuity, and professionalism of our care services.
        </Text>
      </VStack>
    
  );
};

export default CessationSection;
