import React from 'react';
import { Box, Text, Heading, Divider, VStack } from '@chakra-ui/react';

const DisclaimerOfNonInsuranceSection = () => {
  return (
    
      <VStack align="start" spacing={4}>
        {/* Section 5: Disclaimer of Non-Insurance */}
        <Heading as="h2" size="lg" textAlign="center" mb={4}>
          5. Disclaimer of Non-Insurance
        </Heading>
        <Divider />

        {/* Subsection a: Non-Insurance Agreement */}
        <Heading as="h3" size="md">
          a. Non-Insurance Agreement
        </Heading>
        <Text>
          This Agreement is <strong>not a health insurance plan</strong>, <strong>prepaid health plan</strong>, or a substitute for <strong>healthcare coverage</strong>.
        </Text>
        <Text>
          As such, this Agreement is <strong>not subject to health insurance protections</strong> provided for by state law.
        </Text>
        <Text>
          This Agreement solely covers the <strong>described Services</strong> and explicitly <strong>does not include</strong>:
        </Text>
        <VStack align="start" pl={4} spacing={2}>
          <Text>- Hospital services</Text>
          <Text>- Specialist care</Text>
          <Text>- Services not directly provided by our practice</Text>
        </VStack>
        <Text>
          <strong>Concierge services</strong> are rarely covered by health insurance. Therefore:
        </Text>
        <VStack align="start" pl={4} spacing={2}>
          <Text>- We will <strong>not submit claims</strong> to any insurance carrier.</Text>
          <Text>- Payment for the described services will remain <strong>your sole responsibility</strong>.</Text>
        </VStack>

        <Divider my={4} />
        <Text fontWeight="bold" textAlign="center">
          This section clarifies that this Agreement does not constitute health insurance coverage or a substitute for it.
        </Text>
      </VStack>
    
  );
};

export default DisclaimerOfNonInsuranceSection;
