import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Box, Button, Image, Text, HStack, VStack, Icon } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';

const SignaturePad = ({ onSave }) => {
  const sigCanvas = useRef({});
  const [signature, setSignature] = useState(null);
  const [isSaved, setIsSaved] = useState(false);

  // Save Signature
  const saveSignature = () => {
    const image = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
    setSignature(image);
    setIsSaved(true); // Confirmation state
    onSave && onSave(image);
  };

  // Clear Signature
  const clearSignature = () => {
    sigCanvas.current.clear();
    setSignature(null);
    setIsSaved(false);
  };

  return (
    <Box textAlign="center" bg="white" p={4} borderWidth="1px" borderRadius="lg" boxShadow="md">
      {/* Signature Pad */}
      {!isSaved && (
        <VStack spacing={4}>
          <SignatureCanvas
            ref={sigCanvas}
            penColor="black"
            canvasProps={{ width: 400, height: 200, className: 'sigCanvas' }}
          />
          <HStack spacing={4}>
            <Button onClick={saveSignature} colorScheme="blue">Save</Button>
            <Button onClick={clearSignature} colorScheme="red">Clear</Button>
          </HStack>
        </VStack>
      )}

      {/* Confirmation Message */}
      {isSaved && (
        <VStack spacing={2} align="center" mt={4}>
          <Icon as={CheckCircleIcon} w={8} h={8} color="green.500" />
          <Text fontWeight="bold" color="green.600">
            Signature Saved Successfully!
          </Text>
          <Image 
            src={signature} 
            alt="Signature Preview" 
            boxSize="80px" 
            objectFit="contain" 
            border="1px solid gray" 
            borderRadius="md"
          />
          <Button onClick={clearSignature} colorScheme="blue" mt={2}>
            Edit Signature
          </Button>
        </VStack>
      )}
    </Box>
  );
};

export default SignaturePad;
