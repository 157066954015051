import React from 'react';
import { Box, Text, Heading, Divider, VStack } from '@chakra-ui/react';

const ConsentToTreatSection = () => {
  return (
    
      <VStack align="start" spacing={4}>
        {/* Section 3: Consent to Treat */}
        <Heading as="h2" size="lg" textAlign="center" mb={4}>
          3. Consent to Treat
        </Heading>
        <Divider />

        {/* Subsection a: Authorization for Treatment */}
        <Heading as="h3" size="md">
          a. Authorization for Treatment
        </Heading>
        <Text>
          By signing this Agreement, you <strong>acknowledge, consent,</strong> and <strong>authorize OYASU</strong> to provide and administer your <strong>healthcare treatment</strong>.
        </Text>
        <Text>
          Treatment includes, but is not limited to:
        </Text>
        <VStack align="start" pl={4} spacing={2}>
          <Text>- Administration and performance of appropriate treatments</Text>
          <Text>- Administration, monitoring, and use of prescribed medications</Text>
          <Text>- Prescription or performance of necessary procedures</Text>
          <Text>- Diagnostic procedures</Text>
          <Text>- Utilization of cultures and laboratory tests</Text>
        </VStack>
        <Text>
          All procedures and treatments will be carried out under the <strong>judgment of OYASU</strong>, deemed medically necessary or advisable.
        </Text>

        <Divider />

        {/* Subsection b: Advance Consent */}
        <Heading as="h3" size="md">
          b. Advance Consent
        </Heading>
        <Text>
          You acknowledge and understand that this <strong>consent is given in advance</strong> of any specific diagnosis or treatment. Any recommended services are subject to:
        </Text>
        <VStack align="start" pl={4} spacing={2}>
          <Text>- Your <strong>refusal</strong> of the recommended treatment</Text>
          <Text>- Your right to seek a <strong>different medical opinion</strong></Text>
        </VStack>
        <Text>
          You understand and intend this consent to be <strong>continuing in nature</strong>, even after a specific diagnosis has been made and treatment recommended.
        </Text>

        <Divider />

        {/* Subsection c: Revocation of Consent */}
        <Heading as="h3" size="md">
          c. Revocation of Consent
        </Heading>
        <Text>
          This consent will remain in <strong>full force</strong> unless <strong>revoked in writing</strong>.
        </Text>
        <Text>
          Revocation will <strong>not affect any actions</strong> taken prior to receiving your written notice of revocation.
        </Text>

        <Divider my={4} />
        <Text fontWeight="bold" textAlign="center">
          By signing this Agreement, you consent to ongoing medical treatment under the outlined terms.
        </Text>
      </VStack>

  );
};

export default ConsentToTreatSection;
