import React from 'react';
import { Box, Text, Heading, Divider, VStack } from '@chakra-ui/react';

const NonParticipationSection = () => {
  return (
    
      <VStack align="start" spacing={4}>
        {/* Section 6: Non-Participation in Health Insurance */}
        <Heading as="h2" size="lg" textAlign="center" mb={4}>
          6. Non-Participation in Health Insurance
        </Heading>
        <Divider />

        {/* Subsection a: Medicare and Insurance Policies */}
        <Heading as="h3" size="md">
          a. Medicare and Insurance Policies
        </Heading>
        <Text>
          <strong>OYASU’S practice</strong>, as outlined in this Agreement, does <strong>not participate</strong> in any public or private <strong>health insurance plans, including Medicare</strong>.
        </Text>
        <Text>
          <strong>OYASU</strong> does not make any representations regarding <strong>third-party insurance reimbursement</strong>, and such reimbursement is <strong>not anticipated</strong> under this Agreement.
        </Text>
        <Text>
          In compliance with <strong>federal regulations</strong>, <strong>OYASU</strong> has elected <strong>"opt-out" status</strong> for Medicare participation. This means:
        </Text>
        <VStack align="start" pl={4} spacing={2}>
          <Text>- Medicare <strong>cannot be billed</strong> for any services performed under this Agreement.</Text>
          <Text>- You <strong>agree not to bill Medicare</strong> or attempt reimbursement for services provided by <strong>OYASU</strong>.</Text>
        </VStack>
        <Text>
          This Agreement represents a <strong>direct relationship</strong> between <strong>you and OYASU</strong>. All services rendered by <strong>OYASU</strong> are to be <strong>paid directly by you</strong>.
        </Text>

        <Divider my={4} />
        <Text fontWeight="bold" textAlign="center">
          This section ensures clarity regarding the financial and insurance responsibilities under this Agreement.
        </Text>
      </VStack>
    
  );
};

export default NonParticipationSection;
