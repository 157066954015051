import React from 'react';
import { Box, Text, Heading, Divider, VStack } from '@chakra-ui/react';
import FeesSection from './FeeSection';
import InsuranceDisclaimerSection from './InsuranceDisclaimerSection';
import PrivacyAndCommunicationsSection from './PrivacyAndCommunications';
import MembershipLevelsSection from './MembershipLevelsSection';
import MiscellaneousSection from './MiscellaneousSection';
import ServicesSection from './ServicesSection';
import DefinitionsSection from './DefinitionsSection';
import ExclusionsSection from './ExclusionsSection';
import ConsentToTreatSection from './ConsentToTreatSection';
import NonParticipationSection from './NonParticipationSection';
import DisclaimerOfNonInsuranceSection from './DisclaimerOfNonInsurance';
import CessationSection from './CessationSection';

const AgreementText = ({ patientName }) => {
  return (
    <Box >
     
        <Heading as="h2" size="lg" textAlign="center" mb={4}>
          Concierge Medical Agreement
        </Heading>
        <Text>
          This is an Agreement between you as a patient <strong>{patientName || '[PATIENT]'}</strong> and Dr. Kenji Oyasu <strong>[OYASU]</strong> for the provision by OYASU of personal medical services and care as set forth below. The services and treatment explained herein are not exhaustive since the nature of your medical needs and conditions may vary from day to day, but this Agreement contains the structure and format of the medical services you can expect and which OYASU is prepared to provide. This Agreement explains those services and how you and OYASU will work together to ensure good, proper medical care and treatment.
        </Text>

       

        <VStack align="start" spacing={4}>
        {/**
        * Introduction (with dynamic patient name)
        0. Definitions
        1. Services
        2. Exclusions
        3. Consent to Treat
        4. Fees
        5. Disclaimer of non insurance
        6. Non participation in health insurance
        7. Cessation
        8. Privacy and communication
        9. Membership levels
        *. Miscellaneous 
        # {form}
        # E-Signature
         */}
        <DefinitionsSection/>
        <ServicesSection/>
        <ExclusionsSection/>
        <ConsentToTreatSection/>
        <FeesSection />
        <DisclaimerOfNonInsuranceSection/>
        <NonParticipationSection/>
        <CessationSection/>
        <PrivacyAndCommunicationsSection/>
        <MembershipLevelsSection/>
        <MiscellaneousSection/>
        
        

       
      </VStack>

        
      
    </Box>
  );
};

export default AgreementText;
