import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Text,
  VStack,
  Divider,
  Checkbox,
  Button,
  Center,
  Image,
  HStack,
  Icon,
  Link,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { ArrowBackIcon, RepeatIcon } from '@chakra-ui/icons';
import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import ConciergeFormInputs from './ConciergeFormInputs';
import AgreementText from './AgreementText';
import SignaturePad from './SignaturePad';
import axios from 'axios';

const ConciergeAgreementForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    dob: '',
    primaryPhone: '',
    email: '',
    streetAddress: '',
    city: '',
    state: '',
    zipCode: '',
    emergencyContactName: '',
    emergencyContactPhone: '',
    pharmacyName: '',
    pharmacyPhone: '',
    pharmacyAddress: '',
    todaysDate: '', // Auto-filled Today's Date
    additionalNotes: '',
    consentAgreement: false,
    signature: '',
  });

  const [isAgreementScrolled, setIsAgreementScrolled] = useState(false);
  const [isSignatureSaved, setIsSignatureSaved] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const agreementRef = useRef(null);
  const [isReadyToSubmit, setIsReadyToSubmit] = useState(false); // Confirmation step

  /** 📅 Set Today's Date on Mount */
  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    setFormData(prev => ({
      ...prev,
      todaysDate: formattedDate,
    }));
  }, []);

  /** Handle Input Changes */
  const handleChange = e => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  /** Handle Signature Save */
  const handleSignatureSave = signature => {
    setFormData(prev => ({ ...prev, signature }));
    setIsSignatureSaved(true);
    setIsReadyToSubmit(true);
  };

  /** Handle Final Submission */
  const handleFinalSubmit = async () => {
    try {
      setIsSubmitting(true);
      const response = await axios.post('/api/save-form', {
        ...formData,
      });

      if (response.status === 200) {
        setSubmissionStatus('success');
      }
    } catch (error) {
      console.error(
        'Error submitting form:',
        error.response?.data || error.message
      );
      setSubmissionStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  /** Handle Scroll Validation */
  const handleScroll = () => {
    const agreementBox = agreementRef.current;
    if (agreementBox) {
      setIsAgreementScrolled(
        agreementBox.scrollTop + agreementBox.clientHeight >=
          agreementBox.scrollHeight - 1
      );
    }
  };

  useEffect(() => {
    const agreementBox = agreementRef.current;
    agreementBox?.addEventListener('scroll', handleScroll);
    return () => agreementBox?.removeEventListener('scroll', handleScroll);
  }, []);

  /** Handle Confirmation Step */
  const handleProceedToConfirm = () => {
    setIsReadyToSubmit(true);
  };

  const handleEditForm = () => {
    setIsReadyToSubmit(false);
  };

  const handleAddressSelect = async (selected) => {
    if (!selected || !selected.value) {
      console.error("Invalid address selection");
      return;
    }
  
    const { description,placeId, structured_formatting, value } = selected;
  
    // Extract address components from `terms`
    const addressComponents = value?.terms || [];
    let zipCode = "Not available";

    // Construct street address as a combination of the first two components
    const streetAddress =
      addressComponents.length >= 2
        ? `${addressComponents[0].value} ${addressComponents[1].value}`
        : description || "Address not available";
  
    // Extract city, state, and zip code
    const city = addressComponents[2]?.value || ""; // Adjusted for city position
    const state = addressComponents[3]?.value || ""; // Adjusted for state position
    const country = addressComponents[4]?.value || ""; // Zip code, if available
  
    try {
      const apiKey = "AIzaSyBxp-EpGRK9JakC2C0bkayZcjREP24RfLI";
      const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${apiKey}`;
      const response = await axios.get(geocodeUrl);
  
      const addressComponents = response.data.results[0]?.address_components || [];
  
      // Extract relevant fields from address components
      addressComponents.forEach((component) => {
        if (component.types.includes("street_number")) {
          streetAddress = `${component.long_name} ${streetAddress}`;
        }
        if (component.types.includes("locality")) {
          city = component.long_name;
        }
        if (component.types.includes("administrative_area_level_1")) {
          state = component.short_name;
        }
        if (component.types.includes("postal_code")) {
          zipCode = component.long_name;
        }
      });
    } catch (error) {
      console.error("Error fetching address details:", error.message);
    }
    setFormData((prev) => ({
      ...prev,
      streetAddress,
      city,
      state,
      zipCode,
    }));
  };
  
  

  const handlePharmacySelect = async (selected) => {
    if (!selected || !selected.value) {
      console.error("Invalid pharmacy selection");
      return;
    }
  
    // Extract the pharmacy name and address from the selected value
    const { label: fullPharmacyName, value } = selected;
    const { description: fullPharmacyAddress, place_id: placeId } = value;
  
    console.log("Selected Pharmacy Data:", selected);
    console.log("Selected Pharmacy Place ID:", placeId);
  
    if (!placeId) {
      console.error("Place ID is missing for the selected pharmacy");
      return;
    }
  
    // Separate pharmacy name and address
    const pharmacyName = fullPharmacyName.split(",")[0].trim(); // Extract the name (first part before the comma)
    const pharmacyAddress = fullPharmacyAddress.replace(pharmacyName, "").trimStart(", ").trim(); // Remove the name from the address
  
    // Initialize default value for pharmacy phone
    let pharmacyPhone = "Not available";
  
    // Fetch additional details like phone number using Google Places Details API
    try {
      const apiKey = "AIzaSyBxp-EpGRK9JakC2C0bkayZcjREP24RfLI";
      const detailsUrl = `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&fields=name,formatted_phone_number&key=${apiKey}`;
      const response = await axios.get(detailsUrl);
  
      // Extract phone number from API response
      pharmacyPhone = response.data.result?.formatted_phone_number || "Not available";
    } catch (error) {
      console.error("Error fetching pharmacy phone number:", error.message);
    }
  
    // Update the formData state with pharmacy details
    setFormData((prev) => ({
      ...prev,
      pharmacyName: pharmacyName || "Unknown Pharmacy",
      pharmacyAddress: pharmacyAddress || "Address not available",
      pharmacyPhone,
    }));
  };
  
  
  
  

  return (
    <Box
      maxW="800px"
      mx="auto"
      p={6}
      borderWidth="1px"
      borderRadius="lg"
      boxShadow="md"
      mb={20}
    >
      {/* Logo */}
      <Image
        src="https://modernmedconcierge.co/images/MM_Concierge_red ER.png"
        alt="Homepage"
        mb={4}
        width="100%"
      />

      {/* Form Title */}
      <Text fontSize="2xl" fontWeight="bold" mb={4} textAlign="center">
        Concierge Medical Agreement Form
      </Text>
      <Divider mb={4} />

      {submissionStatus === 'error' && (
        <Box
          p={4}
          borderWidth="1px"
          borderRadius="lg"
          background="red.50"
          boxShadow="sm"
          textAlign="center"
        >
          <Icon as={WarningIcon} w={8} h={8} color="red.500" />
          <Text fontSize="lg" fontWeight="bold" color="red.600" mt={2}>
            Failed to Submit Form
          </Text>
          <Text mt={2}>Please try again later.</Text>
          <VStack spacing={2} mt={4}>
            <Link
              href="/form"
              color="blue.500"
              fontWeight="medium"
              display="flex"
              alignItems="center"
            >
              <Icon as={RepeatIcon} mr={1} /> Return to Form
            </Link>
          </VStack>
        </Box>
      )}

      {submissionStatus === 'success' ? (
        <Box
          p={4}
          borderWidth="1px"
          borderRadius="lg"
          background="green.50"
          boxShadow="sm"
          textAlign="center"
        >
          <Icon as={CheckCircleIcon} w={8} h={8} color="green.500" />
          <Text fontSize="lg" fontWeight="bold" color="green.600" mt={2}>
            Form Submitted Successfully!
          </Text>
          <Text mt={2}>Thank you for completing the agreement.</Text>
          <VStack spacing={2} mt={4}>
            <Link
              href="/"
              color="blue.500"
              fontWeight="medium"
              display="flex"
              alignItems="center"
            >
              <Icon as={ArrowBackIcon} mr={1} /> Return to Homepage
            </Link>
          </VStack>
        </Box>
      ) : isReadyToSubmit ? (
        <Box
          borderWidth="1px"
          borderRadius="lg"
          p={4}
          bg="gray.50"
          boxShadow="md"
        >
          <Text fontSize="lg" fontWeight="bold" mb={4}>
            Please Review Your Information Before Final Submission
          </Text>
          <VStack align="start" spacing={2}>
            {Object.entries(formData).map(([key, value]) => {
              if (key === 'signature') return null; // Hide signature in text summary
              if (key === 'consentAgreement') {
                return (
                  <Text key={key}>
                    <strong>Consent Agreement:</strong>{' '}
                    {value ? 'True' : 'False'}
                  </Text>
                );
              }
              return (
                <Text key={key}>
                  <strong>{key.replace(/([A-Z])/g, ' $1')}:</strong>{' '}
                  {value || 'N/A'}
                </Text>
              );
            })}

            {/* Signature Preview */}
            {formData.signature && (
              <Box mt={4}>
                <Text fontWeight="bold">Signature Preview:</Text>
                <Image
                  src={formData.signature}
                  alt="Signature Preview"
                  boxSize="80px"
                  objectFit="contain"
                  border="1px solid gray"
                  borderRadius="md"
                  mt={2}
                />
              </Box>
            )}
          </VStack>

          {/* Final Submission Buttons */}
          <Button mt={4} onClick={handleFinalSubmit} colorScheme="green">
            Confirm & Submit
          </Button>
          <Button mt={2} onClick={handleEditForm} variant="outline">
            Edit Information
          </Button>
        </Box>
      ) : (
        <form onSubmit={handleProceedToConfirm}>
          <VStack spacing={4} align="stretch">
            {/* Form Inputs */}
            <ConciergeFormInputs
              formData={formData}
              handleAddressSelect={handleAddressSelect}
              handleChange={handleChange}
              handlePharmacySelect={handlePharmacySelect}
            />

            {/* Notification Bar */}
            {!isAgreementScrolled && (
              <Alert status="info" mb={4} borderRadius="md">
                <AlertIcon />
                Please scroll to the bottom of the agreement and check the box
                to proceed.
              </Alert>
            )}
            {/* Agreement Section */}
            <Box
              ref={agreementRef}
              borderWidth="1px"
              borderRadius="lg"
              p={4}
              overflowY="auto"
              maxH="500px"
              background="gray.50"
              boxShadow="inner"
            >
              <AgreementText
                patientName={`${formData.firstName} ${formData.lastName}`}
              />
            </Box>

            {/* Checkbox */}
            <Box mb={2}>
              <Text fontSize="sm" color="gray.500">
                You must scroll through the agreement and check this box to
                proceed.
              </Text>
            </Box>
            <Checkbox
              isDisabled={!isAgreementScrolled}
              isChecked={formData.consentAgreement}
              onChange={handleChange}
              name="consentAgreement"
            >
              I have read and agree to the terms and conditions.
            </Checkbox>

            {/* Signature Pad */}
            {formData.consentAgreement && !isSignatureSaved && (
              <Box mb={4}>
                <Text fontSize="sm" color="gray.500" mb={2}>
                  Please provide your signature below to confirm.
                </Text>
                <Center>
                  <SignaturePad onSave={handleSignatureSave} />
                </Center>
              </Box>
            )}

            {/* Proceed Button */}
            {isSignatureSaved && (
              <Button type="submit" colorScheme="blue" width="full">
                Proceed to Confirmation
              </Button>
            )}
          </VStack>
        </form>
      )}
    </Box>
  );
};

export default ConciergeAgreementForm;
