import React, { useState, useEffect } from 'react';
import {
  ChakraProvider,
  Box,
  HStack,
  VStack,
  Image,
  theme,
  useDisclosure,
  IconButton,
  Tabs,
  TabList,
  Tab,
  Link,
  Spacer,
  Center,
  Text,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Route,
  Routes,
  useLocation,
  Link as RouterLink,
} from 'react-router-dom';

import ConnectMenu from './components/ConnectMenu';
import MembershipLevels from './components/MembershipLevels';
import ServicesAvailable from './components/ServicesAvailable';
import WhatMakesMMCDifferent from './components/WhatMakesMMCDifferent';
import MMCWhyIDoThis from './components/WhyIDoThis';
import ConciergeAgreementForm from './components/ConciergeAgreementForm';
import NotFound from './components/NotFound';
import HomePage from './components/Homepage';
import MMRHomepage from './components/MMRHomepage';
import GetStartedModal from './components/GetStartedModal';
import ChicagoHomepage from './components/ChicagoHomepage';

function App() {
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeTab, setActiveTab] = useState('home');

  useEffect(() => {
    if (location.pathname === '/services') setActiveTab('services');
    else if (location.pathname === '/different') setActiveTab('difference');
    else if (location.pathname === '/why') setActiveTab('why');
    else if (location.pathname === '/modernmedrecovery')
      setActiveTab('modernmedrecovery');
    else if (location.pathname === '/modernmedconcierge')
      setActiveTab('modernmedconcierge');
    else if (location.pathname === '/modernmedchicago')
      setActiveTab('modernmedchicago');
    else setActiveTab('home');
  }, [location.pathname]);

  const renderContent = () => {
    switch (activeTab) {
      case 'services':
        return (
          <Center flexDirection="column">
            <Image
              src="https://modernmedconcierge.co/images/services_available.png"
              maxW="500px"
              height="auto"
              objectFit="contain"
              alt="Services Available"
              mb={4}
              width="100%"
            />
            <ServicesAvailable />
            <MembershipLevels />
          </Center>
        );
      case 'difference':
        return (
          <Center flexDirection="column">
            <Image
              src="https://modernmedconcierge.co/images/What_makes_MMC_different.png"
              maxW="500px"
              height="auto"
              objectFit="contain"
              alt="What Makes MMC Different"
              mb={4}
              width="100%"
            />
            <WhatMakesMMCDifferent />
          </Center>
        );
      case 'why':
        return (
          <Center flexDirection="column">
            <Image
              src="https://modernmedconcierge.co/whyIdoThis.png"
              maxW="500px"
              height="auto"
              objectFit="contain"
              alt="Why I Do This"
              mb={4}
              width="100%"
            />
            <MMCWhyIDoThis />
          </Center>
        );
      case 'modernmedrecovery':
        return (
          <VStack justify="center" align="center">
            <Box maxWidth="600px" width="100%">
              <Center>
                <Image
                  src="/mm-recovery.png"
                  alt="Modern Med Recovery"
                  width="100%"
                  maxW="500px"
                />
              </Center>
              <MMRHomepage />
            </Box>
          </VStack>
        );
      case 'modernmedconcierge':
        return (
          <VStack justify="center" align="center">
            <Box maxWidth="600px" width="100%">
              <Center>
                <Image
                  src="https://modernmedconcierge.co/images/MM_Concierge_red ER.png"
                  maxW="500px" // Set max width to 500px
                  height="auto"
                  objectFit="contain"
                  alt="ModernMed Concierge"
                  width="100%" // Ensure image is responsive
                />
              </Center>
              <HomePage />
            </Box>
          </VStack>
        );
      case 'modernmedchicago':
        return (
          <ChicagoHomepage/>
        );
      default:
        return (
          <ChicagoHomepage/>
        );
    }
  };

  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl" bg="white" minH="100vh" p={1}>
        {/* Header */}
        <HStack mb={8} justifyContent="center" align="center" p={2} shadow="md">
          {/* Left: Logo */}
          <Box flex="1" display="flex" justifyContent="flex-start">
            <Link as={RouterLink} to="/" onClick={() => setActiveTab('home')}>
              <Image
                src="/mm-chicago.png"
                alt="Logo"
                width={{ base: '150px', md: '250px' }}
                height="auto"
              />
            </Link>
          </Box>

          {/* Center: Tabs & Images */}
          <Box flex="2" textAlign="center">
            <VStack spacing={2} align="center">
              {/* Navigation Tabs */}
              <HStack
                display={{ base: 'none', md: 'flex' }}
                justifyContent="center"
              >
                <Tabs variant="unstyled">
                  <TabList>
                    <Tab
                      as={RouterLink}
                      to="/services"
                      _selected={{ color: 'blue.500' }}
                    >
                      Services available
                    </Tab>
                    <Tab
                      as={RouterLink}
                      to="/different"
                      _selected={{ color: 'blue.500' }}
                    >
                      What makes us different
                    </Tab>
                    <Tab
                      as={RouterLink}
                      to="/why"
                      _selected={{ color: 'blue.500' }}
                    >
                      Why I do this
                    </Tab>
                  </TabList>
                </Tabs>
              </HStack>

              {/* Image Tabs */}
              <HStack spacing={4} justifyContent="center">
                <Link
                  as={RouterLink}
                  to="/modernmedconcierge"
                  onClick={() => setActiveTab('modernmedconcierge')}
                >
                  <Image
                    src="/mm-concierge.png"
                    alt="MM Concierge"
                    width="200px"
                    objectFit="contain"
                    cursor="pointer"
                  />
                </Link>
                <Box width="1px" height="30px" bg="gray.300" />
                <Link
                  as={RouterLink}
                  to="/modernmedchicago"
                  onClick={() => setActiveTab('modernmedchicago')}
                >
                  <Image
                    src="/mm-chicago.png"
                    alt="MM Chicago"
                    width="200px"
                    objectFit="contain"
                    cursor="pointer"
                  />
                </Link>
                <Box width="1px" height="30px" bg="gray.300" />
                <Link
                  as={RouterLink}
                  to="/modernmedrecovery"
                  onClick={() => setActiveTab('modernmedrecovery')}
                >
                  <Image
                    src="/mm-recovery.png"
                    alt="MM Recovery"
                    width="200px"
                    objectFit="contain"
                    cursor="pointer"
                  />
                </Link>
              </HStack>
            </VStack>
          </Box>

          {/* Right: Connect Menu */}
          <Box flex="1" display="flex" justifyContent="flex-end">
            <HStack display={{ base: 'none', md: 'flex' }}>
              <GetStartedModal />
              <ConnectMenu />
            </HStack>
            <IconButton
              icon={<HamburgerIcon />}
              display={{ base: 'flex', md: 'none' }}
              onClick={onOpen}
              aria-label="Open Menu"
            />
          </Box>
        </HStack>

        {/* Main Tab Content */}

        {/* Routes */}
        <Routes>
          <Route path="/" element={renderContent()} />
          <Route path="/modernmedchicago" element={renderContent()} />
          <Route path="/modernmedconcierge" element={renderContent()} />
          <Route path="/modernmedrecovery" element={renderContent()} />
          <Route path="/services" element={renderContent()} />
          <Route path="/different" element={renderContent()} />
          <Route path="/why" element={renderContent()} />
          <Route path="/form" element={<ConciergeAgreementForm />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Box>
    </ChakraProvider>
  );
}

export default App;
