import React from 'react';
import { Box, Text, Heading, Divider, VStack, Link } from '@chakra-ui/react';

const MiscellaneousSection = () => {
  return (
    
      <VStack align="start" spacing={4}>
        {/* Section: Miscellaneous */}
        <Heading as="h2" size="lg" textAlign="center" mb={4}>
          Miscellaneous
        </Heading>
        <Divider />

        {/* Dispute Resolution */}
        <Heading as="h3" size="md">
          a. Dispute Resolution
        </Heading>
        <Text>
          In the unlikely event that a dispute arises, we will work with you to <strong>resolve that dispute in good faith</strong>, including by way of <strong>mediation</strong> through a neutral mediator or mediation service.
        </Text>
        <Text>
          If mediation is unsuccessful, final disposition shall be resolved by <strong>binding arbitration</strong> and enforced by a court in the <strong>State of Illinois</strong> of competent jurisdiction.
        </Text>
        <Text>
          <strong>OYASU</strong> will choose the provider of arbitration or mediation service, and each party shall submit the names of <strong>five arbitrators</strong> or mediators. If there’s a match, that person will serve as mediator or arbitrator.
        </Text>
        <Text>
          If no match occurs, a <strong>random draw</strong> will select one person from either list. Both parties agree to:
        </Text>
        <VStack align="start" pl={4} spacing={2}>
          <Text>- Waive their right to a <strong>jury trial</strong>.</Text>
          <Text>- Waive their right to file a <strong>lawsuit</strong> in a court of jurisdiction.</Text>
        </VStack>
        <Text>
          The determination of the arbitrator will be final, and both parties agree to abide by and be responsible for any relief granted.
        </Text>

        <Divider />

        {/* Governing Law */}
        <Heading as="h3" size="md">
          b. Governing Law
        </Heading>
        <Text>
          This Agreement shall be subject to and <strong>governed by the laws of Illinois</strong>, without regard to any conflicts of law provisions. Both parties specifically waive all jurisdictional rights under the laws of any other state.
        </Text>

        <Divider />

        {/* Notice to Consumers */}
        <Heading as="h3" size="md">
          c. Notice to Consumers
        </Heading>
        <Text>
          Medical doctors are <strong>licensed and regulated</strong> by the Illinois Department of Financial and Professional Regulation. You can access their website here:{' '}
          <Link href="https://idfpr.illinois.gov/profs/physicians.html" color="blue.500" isExternal>
            Illinois Department of Financial and Professional Regulation
          </Link>
        </Text>

        <Divider />

        {/* Other */}
        <Heading as="h3" size="md">
          d. Other
        </Heading>
        <Text>
          No amendment or variation of the terms of this Agreement shall be valid unless <strong>mutually agreed to in writing</strong>. This Agreement is <strong>personal to you</strong> and may not be assigned.
        </Text>
        <Text>
          We may need to <strong>delegate certain duties</strong> under this Agreement. You consent to such delegation, except that a delegation of a member patient’s primary care physician requires your explicit agreement.
        </Text>
        <Text>
          This Agreement constitutes the <strong>entire agreement</strong> between us and supersedes all prior agreements, representations, and understandings, whether written or oral.
        </Text>
        <Text>
          If any provision of this Agreement is deemed <strong>unenforceable</strong>, it shall not affect the remaining provisions, which will remain in full effect.
        </Text>
        <Text>
          In the event that any refund of fees is required, you agree to pay an amount equivalent to the <strong>fair market value of the services</strong> already rendered during that period.
        </Text>
        <Text>
          Any provision extending obligations beyond the term of this Agreement will <strong>survive its termination</strong>.
        </Text>
        <Text>
          No waiver of any breach will be construed as a waiver of subsequent breaches, and any delay in addressing a breach will not be interpreted as a waiver.
        </Text>

        <Divider my={4} />
        <Text fontWeight="bold" textAlign="center">
          This section outlines the legal and operational framework governing this Agreement.
        </Text>
      </VStack>
    
  );
};

export default MiscellaneousSection;
