import React from 'react';
import { Box, Text, Heading, Divider, VStack } from '@chakra-ui/react';

const DefinitionsSection = () => {
  return (
    
      <VStack align="start" spacing={4}>
        {/* Section: Definitions */}
        <Heading as="h2" size="lg" textAlign="center" mb={4}>
          DEFINITIONS
        </Heading>
        <Divider />

        {/* Definition A: COMMUNICATIONS */}
        <Heading as="h3" size="md">
          A. COMMUNICATIONS
        </Heading>
        <Text>
          <strong>COMMUNICATIONS</strong> refers to <strong>text messages, emails, video calls, telephone calls, personal consultations,</strong> and other related methods to transfer information from <strong>patient to physician</strong>.
        </Text>

        <Divider />

        {/* Definition B: CONCIERGE */}
        <Heading as="h3" size="md">
          B. CONCIERGE
        </Heading>
        <Text>
          <strong>CONCIERGE</strong> refers to a method of providing medical services and treatment to a <strong>member patient</strong> through:
        </Text>
        <VStack align="start" pl={4} spacing={2}>
          <Text>- Personal visits</Text>
          <Text>- Telephonic communications</Text>
          <Text>- Email communications</Text>
          <Text>- Video or text messaging</Text>
        </VStack>
        <Text>
          This method offers patients <strong>greater and quicker access</strong> to the physician rendering services or medical treatment.
        </Text>

        <Divider />

        {/* Definition C: HORMONE OPTIMIZATION */}
        <Heading as="h3" size="md">
          C. HORMONE OPTIMIZATION
        </Heading>
        <Text>
          <strong>HORMONE OPTIMIZATION</strong> is the medical process of assessing whether hormone levels in the body are <strong>below their optimal threshold</strong>. If so, treatment may be provided to <strong>improve hormone function</strong>.
        </Text>

        <Divider />

        {/* Definition D: MEDICATION ASSISTED WEIGHT LOSS */}
        <Heading as="h3" size="md">
          D. MEDICATION ASSISTED WEIGHT LOSS
        </Heading>
        <Text>
          <strong>MEDICATION ASSISTED WEIGHT LOSS</strong> is a weight-loss program that utilizes:
        </Text>
        <VStack align="start" pl={4} spacing={2}>
          <Text>- Prescription medications</Text>
          <Text>- Suggested lifestyle changes</Text>
          <Text>- Diet adjustments</Text>
        </VStack>
        <Text>
          This approach enhances or assists in effective weight loss management.
        </Text>

        <Divider />

        {/* Definition E: MEMBER-PATIENT */}
        <Heading as="h3" size="md">
          E. MEMBER-PATIENT
        </Heading>
        <Text>
          A <strong>MEMBER-PATIENT</strong> is an individual who has executed the <strong>Concierge Medical Agreement</strong> and any appendices or exhibits attached to it, and has <strong>paid for the services</strong> outlined in this Agreement.
        </Text>

        <Divider />

        {/* Definition F: OYASU */}
        <Heading as="h3" size="md">
          F. OYASU
        </Heading>
        <Text>
          <strong>OYASU</strong> refers to <strong>Dr. Kenji Oyasu, M.D.</strong>, a licensed physician in the <strong>State of Illinois</strong>, who serves as the <strong>primary medical provider</strong> under the terms and conditions of this Agreement.
        </Text>

        <Divider />

        {/* Definition G: PRACTICE */}
        <Heading as="h3" size="md">
          G. PRACTICE
        </Heading>
        <Text>
          <strong>PRACTICE</strong> refers to the <strong>medical and administrative employees</strong>, including <strong>OYASU</strong>, who:
        </Text>
        <VStack align="start" pl={4} spacing={2}>
          <Text>- Coordinate your care and treatment</Text>
          <Text>- Oversee scheduling</Text>
          <Text>- Handle payments</Text>
          <Text>- Manage prescriptions</Text>
          <Text>- Recommend medical testing and treatments</Text>
        </VStack>

        <Divider />

        {/* Definition H: VIRTUAL CARE */}
        <Heading as="h3" size="md">
          H. VIRTUAL CARE
        </Heading>
        <Text>
          <strong>VIRTUAL CARE</strong> includes medical treatment, advice, prescriptions, or referrals provided <strong>remotely</strong> via:
        </Text>
        <VStack align="start" pl={4} spacing={2}>
          <Text>- Telephone</Text>
          <Text>- Video messaging</Text>
          <Text>- Text messages</Text>
          <Text>- Emails</Text>
        </VStack>
        <Text>
          These services do not require an <strong>in-person consultation</strong> or meeting with <strong>OYASU</strong>.
        </Text>

        <Divider my={4} />
        <Text fontWeight="bold" textAlign="center">
          These definitions provide clarity and structure to the terms used throughout this Agreement.
        </Text>
      </VStack>
    
  );
};

export default DefinitionsSection;
