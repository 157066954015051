import React from 'react';
import { Box, Text, Heading, Divider, VStack } from '@chakra-ui/react';

const ServicesSection = () => {
  return (
    
      <VStack align="start" spacing={4}>
        {/* Section 1: Services/Concierge Format */}
        <Heading as="h2" size="lg" textAlign="center" mb={4}>
          1. Services/Concierge Format
        </Heading>
        <Divider />

        {/* Unique Relationship */}
        <Heading as="h3" size="md">
          a. Unique Relationship
        </Heading>
        <Text>
          You agree and understand that you are entering into a <strong>unique relationship</strong> with <strong>OYASU</strong>, who will serve in the role of an adjunct to your primary care physician. 
        </Text>
        <Text>
          <strong>OYASU’s</strong> purpose and goal are to:
        </Text>
        <VStack align="start" pl={4} spacing={2}>
          <Text>- Consult with you regarding your medical conditions, concerns, and needs.</Text>
          <Text>- Prescribe or recommend treatments if required.</Text>
          <Text>- Refer you to other medical specialists if necessary or medically sound.</Text>
        </VStack>
        <Text>
          Your access to <strong>OYASU</strong> will be available through in-person visits, phone calls, emails, or text messages. 
          By increasing access, the goal is to better monitor ongoing medical conditions, quickly address new medical concerns, provide timely medical advice, and oversee your overall medical care.
        </Text>

        <Divider />

        {/* Virtual Care */}
        <Heading as="h3" size="md">
          b. Virtual Care
        </Heading>
        <Text>
          Your time is valuable, and you may choose to receive your care <strong>virtually via telephone or text</strong>. 
          Virtual Care is considered a vital part of our commitment to providing timely and meaningful medical advice and recommendations.
        </Text>

        <Divider />

        {/* Out of Office Visits */}
        <Heading as="h3" size="md">
          c. Out of Office Visits
        </Heading>
        <Text>
          Occasionally, and in <strong>emergency situations</strong>, it may be most appropriate for you to receive medical care at your home or office. 
          While <strong>OYASU</strong> can provide out-of-office visits based on availability, these visits are not guaranteed and depend on the level of medical urgency and <strong>OYASU's schedule</strong>.
        </Text>

        <Divider />

        {/* Annual Exam */}
        <Heading as="h3" size="md">
          d. Annual Exam
        </Heading>
        <Text>
          Periodic oversight is crucial for your health and longevity. 
          As part of your plan, <strong>OYASU</strong> will conduct a <strong>comprehensive annual examination</strong> at his medical office. 
          This exam aims to monitor existing health conditions and recommend preventative treatments.
        </Text>

        <Divider />

        {/* In-Office Ancillary Services */}
        <Heading as="h3" size="md">
          e. In-Office Ancillary Services
        </Heading>
        <Text>
          Your care may require <strong>in-office ancillary services</strong>, including laboratory tests or electrocardiography. 
          These services are <strong>not included</strong> in your membership plan.
        </Text>
        <Text>
          While these services may be prescribed by <strong>OYASU</strong>, the cost must be handled by the <strong>PATIENT</strong>, including insurance claims submissions.
        </Text>

        <Divider />

        {/* Specialty Care Coordination */}
        <Heading as="h3" size="md">
          f. Specialty Care Coordination
        </Heading>
        <Text>
          If your care requires services from <strong>medical specialists</strong> outside of <strong>OYASU’s</strong> office, we will:
        </Text>
        <VStack align="start" pl={4} spacing={2}>
          <Text>- Source appropriate referrals expediently.</Text>
          <Text>- Coordinate with specialists to receive test results, findings, and reports.</Text>
          <Text>- Work with specialists to ensure your treatment plan is cohesive.</Text>
        </VStack>
        <Text>
          Please note that <strong>hospital services are not included</strong> in your membership. 
          If you are admitted to a hospital, we will work diligently with your hospital providers when medically necessary or appropriate to ensure the best care is provided.
        </Text>

        <Divider my={4} />
        <Text fontWeight="bold" textAlign="center">
          These services are designed to ensure accessible and high-quality medical care tailored to your needs.
        </Text>
      </VStack>
    
  );
};

export default ServicesSection;
