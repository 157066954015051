import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from "@chakra-ui/react";

const GetStartedModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      {/* Get Started Button */}
      <Button bg="white" onClick={onOpen}>
        Get Started
      </Button>

      {/* Modal */}
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Get Started</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSdSILvqzSZIyWXTAtORIGEJUHnQOYkgBMcu0ARxOR4gYS6LrA/viewform?usp=sf_link"
              width="100%"
              height="500px"
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
            >
              Loading…
            </iframe>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default GetStartedModal;
