import { Box, Text, Stack } from "@chakra-ui/react";
import GetStartedModal from "./GetStartedModal";

const MMRHomepage = () => {
  return (
    <Box maxW="600px" mx="auto" px={6} py={6} textAlign="center">
      {/* Heading */}

      {/* Description */}
      <Stack spacing={6} align="center">
        <Text fontFamily="Sentinel" fontSize="md" lineHeight="1.6" textAlign="justify">
          Dr. Kenji Oyasu is a physician executive and founder of ModernMed Recovery. 
          He actively manages and provides physician oversight for the medication-assisted 
          treatment of patients struggling with opioid addiction.
        </Text>

        <Text fontFamily="Sentinel" fontSize="md" lineHeight="1.6" textAlign="justify">
          Dr. Oyasu’s 26 years as an emergency room physician exposed him to the growing 
          epidemic of opioid addiction, along with the lack of treatment resources. 
          He felt the need to create a medication-assisted treatment program where patients 
          are treated by caring physicians with professionalism.
        </Text>

        <Text fontFamily="Sentinel" fontSize="md" lineHeight="1.6" textAlign="justify">
          He has a special interest in linking the success of in-patient detoxification 
          with long-term meaningful recovery. He currently serves on the board of directors 
          for the Lake County Opioid Initiative and the medical committee for the Chicago Area 
          Opioid Task Force. Dr. Oyasu has been recognized locally and nationally as an 
          advocate for compassionate treatment of this very special patient population.
        </Text>
      </Stack>
      <Box p={4}>
      <Text fontFamily="Sentinel" fontSize="md" lineHeight="1.6" textAlign="justify">

For new patient registration: <GetStartedModal/> </Text>
      </Box>
    </Box>
  );
};

export default MMRHomepage;
