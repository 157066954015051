import React from 'react';
import {
  Box,
  VStack,
  HStack,
  Text,
  Divider,
  Badge,
  Icon,
  List,
  ListItem,
  ListIcon,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';
import { FaCircle, FaCheckCircle } from 'react-icons/fa';

const levelDetails = [
  {
    title: 'Bronze Level',
    color: 'bronze',
    iconColor: 'orange.400',
    description:
      'Focuses on the management and prescription of medications for various previously diagnosed conditions. This membership allows the patient to communicate with practice by text or email with a response within 6-8 hours.',
    fees: [
      'Initial Consultation Fee: $300.00',
      'Medication Management Fee: $120.00/month OR $300.00/quarterly OR $1,200.00 yearly fee',
    ],
  },
  {
    title: 'Silver Level',
    color: 'silver',
    iconColor: 'gray.400',
    description:
      'This level focuses on hormone optimization. Hormone therapy is effectively used for treatment of low libido, anti-aging, longevity, menopausal symptoms, metabolic issues, insulin resistance, hormone dysregulation and MUCH more! Includes all benefits of Bronze Level.',
    fees: [
      'Initial Consultation Fee: $300.00',
      'Hormone Therapy Fee: $240.00/month OR $600.00/quarterly OR $2,400.00 yearly fee',
    ],
    note: '**This level does NOT include the cost of BHRT medication. See BHRT Prescription Pricing for additional cost information**',
  },
  {
    title: 'Gold Level',
    color: 'gold',
    iconColor: 'yellow.400',
    description:
      'Focused on prescription-based weight-loss. This level includes a physical exam, history and ordering of appropriate labs. Once a month, the patient will have an appointment in person to track weight-loss, to oversee and manage the prescription/s and rate of weight loss. Includes all benefits of the Bronze Level.',
    fees: [
      'Initial Consultation Fee: $300.00',
      'GLP-1 Management Fee: $125.00/week OR $500.00/month for Semaglutide; $600/month for FULL DOSE',
      '$150.00/week OR $600.00/month for Tirzepatide; $700/month for FULL DOSE',
    ],
    note: '**This level includes the cost of the GLP-1 medication. We offer both Semaglutide & Tirzepatide.**',
  },
  {
    title: 'Platinum Level',
    color: 'platinum',
    iconColor: '#E5E4E2',
    description:
      'This Level allows all services and treatments described above in Bronze, Silver, and Gold levels, including weight-loss management, hormone optimization, and prescription management. You will be in close contact with Dr. Oyasu, including text messages, phone calls, and emails. You will have an Initial In-person consultation then appointments one-time per month either virtually or in person. You will also receive a yearly physical exam which includes labs, EKG, and thorough physical exam.',
    fees: [
      'Initial Consultation Fee: $300.00/month',
      'GLP-1 + Hormone Therapy + Medication Management Fee: $750.00/month OR $1,875.00/quarterly OR $7,500.00 yearly fee',
    ],
    note: '**This level includes the cost of the GLP-1 medication. We offer both Semaglutide & Tirzepatide. This level does NOT include the cost of BHRT medication. See BHRT Prescription Pricing for additional cost information**',
  },
];

const MembershipLevels = () => {
  return (
    <VStack spacing={6} align="stretch">
      {levelDetails.map((level, index) => (
        <Box
          key={index}
          p={6}
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="md"
          bg="white"
          position="relative"
        >
          {/* Title with Icon */}
          <HStack spacing={4} align="center">
            <Icon as={FaCircle} color={level.iconColor} w={6} h={6} />
            <Text fontSize="xl" fontWeight="bold" textTransform="uppercase">
              {level.title}
            </Text>
          </HStack>

          {/* Description */}
          <Text mt={4} fontSize="md">
            {level.description}
          </Text>

          {/* Fees */}
          <Table variant="simple" size="sm" mt={4}>
            <Thead>
              <Tr>
                <Th>Fee Type</Th>
                <Th>Options</Th>
              </Tr>
            </Thead>
            <Tbody>
              {Object.entries(level.fees).map(([feeType, feeValues], feeIndex) => (
                <Tr key={feeIndex}>
                  <Td fontWeight="medium">{feeType}</Td>
                  <Td>
                    {Array.isArray(feeValues) ? (
                      <List spacing={1}>
                        {feeValues.map((value, valueIndex) => (
                          <ListItem key={valueIndex}>
                            <ListIcon as={FaCheckCircle} color="green.400" />
                            {value}
                          </ListItem>
                        ))}
                      </List>
                    ) : (
                      feeValues
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>

          {/* Note */}
          {level.note && (
            <Text mt={4} fontSize="sm" color="gray.600" fontStyle="italic">
              {level.note}
            </Text>
          )}

          <Divider mt={6} />
          <Badge
            mt={4}
            colorScheme={level.color}
            fontSize="sm"
            alignSelf="start"
          >
            {level.title.split(' ')[0]} Membership
          </Badge>
        </Box>
      ))}
    </VStack>
  );
};

export default MembershipLevels;
