import React from "react";
import { VStack, Box, Text, Center, Image } from "@chakra-ui/react";

const ChicagoHomepage = () => {
  return (
    <VStack justify="center" align="center" gap={8}>
      <Box maxWidth="600px" width="100%">
        <Text fontSize="lg" mb={4} fontFamily="Sentinel">
          WELCOME TO
        </Text>

        <Center mt={1} p={4}>
          <Image
            src="/mm-chicago.png"
            alt="Logo"
            w="100%"
            height="auto"
          />
        </Center>

        <Center>
          <Text
            justify="center"
            fontSize="lg"
            mb={4}
            fontFamily="Sentinel"
            noOfLines={2}
            maxWidth={300}
          >
            Home of health and wellness services provided by Dr. Kenji Oyasu.
          </Text>
        </Center>

        <Image src="./homepage2-profile.png" width="100%" objectFit="contain" />
      </Box>
    </VStack>
  );
};

export default ChicagoHomepage;
