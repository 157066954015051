import React from 'react';
import { Box, Text, Heading, Divider, VStack } from '@chakra-ui/react';

const FeesSection = ({ patientName }) => {
  return (
    
      <VStack align="start" spacing={4}>
        {/* Section 4: Fees */}
        <Heading as="h2" size="lg" textAlign="center" mb={4}>
          4. Fees
        </Heading>
        <Divider />

        <Text>
          Your membership is for <strong>one year</strong> and will <strong>automatically renew</strong> until you decide not to renew. 
          Your annual fee is identified on the payment form and may be made in <strong>annual, quarterly, or monthly installments</strong>. 
          The annual payment is due no later than the <strong>first day of the year</strong> of the annual membership. 
        </Text>

        <Text>
          If you opt for <strong>quarterly installment payments</strong>, payments are due on the <strong>first day of each quarter</strong>. 
          For <strong>monthly installments</strong>, payments are due on the <strong>first day of each month</strong> during the applicable year in which you are enrolled.
        </Text>

        <Text>
          If your plan begins <strong>after the first day</strong> of a particular month, and you have opted for monthly installments, you will owe payment for the shortened month on a <strong>pro rata basis</strong>, followed by full monthly payments on the first of each month.
        </Text>

        <Divider />

        <Text>
          <strong>Late Payments:</strong> Late payments are not acceptable as they disrupt our coordination of care. 
          Payments that are <strong>30 days overdue</strong> will incur a <strong>$200.00 reactivation fee</strong>.
        </Text>

        <Text>
          To remain financially viable, <strong>OYASU</strong> reserves the right to <strong>change its fees</strong> at any time with <strong>90 days’ notice</strong> to you.
        </Text>

        <Divider />

        <Text>
          <strong>Membership Cancellation:</strong> You may <strong>end or cancel</strong> your membership upon <strong>30 days’ written notice</strong>. 
          Any remaining membership fee paid will be <strong>prorated and refunded</strong> if membership fees were paid upfront.
        </Text>

        <Text>
          If you choose to discontinue your membership and later wish to <strong>re-enroll</strong>, we reserve the right to:
        </Text>
        <VStack align="start" pl={4} spacing={2}>
          <Text>- Decline re-enrollment.</Text>
          <Text>- Require payment of a <strong>re-enrollment fee</strong> equivalent to the months of absent payments (not to exceed <strong>12 months</strong>).</Text>
        </VStack>

        <Divider />

        <Text>
          <strong>Payment Information:</strong> You will be required to keep a valid form of <strong>payment on file</strong> for electronic payments. 
          If the form of payment provided expires or becomes invalid, you agree to <strong>promptly update</strong> your payment information.
        </Text>

        <Text>
          In the event there are <strong>costs associated with invalid payment information</strong>, such charges will be applied to your account.
        </Text>

        
      </VStack>
    
  );
};

export default FeesSection;
