import React from 'react';
import { Box, Text, Heading, Divider, VStack } from '@chakra-ui/react';

const ExclusionsSection = () => {
  return (
    
      <VStack align="start" spacing={4}>
        {/* Section 2: Exclusions */}
        <Heading as="h2" size="lg" textAlign="center" mb={4}>
          2. Exclusions
        </Heading>
        <Divider />

        {/* Subsection a: Excluded Services */}
        <Heading as="h3" size="md">
          a. Excluded Services
        </Heading>
        <Text>
          You may need the care of:
        </Text>
        <VStack align="start" pl={4} spacing={2}>
          <Text>- Emergency rooms</Text>
          <Text>- Outside laboratory testing</Text>
          <Text>- Pathology studies</Text>
          <Text>- Prescribed medications</Text>
          <Text>- Radiologic imaging</Text>
          <Text>- Specialist consultations or treatment</Text>
          <Text>- Surgery</Text>
          <Text>- Urgent care centers</Text>
          <Text>- Specialty vaccinations</Text>
          <Text>- Pap smears</Text>
          <Text>- Other healthcare services outside the scope of this Agreement</Text>
        </VStack>
        <Text>
          These services are <strong>not included</strong> in the membership fee.
        </Text>
        <Text>
          We <strong>highly recommend</strong> that you maintain <strong>health insurance</strong>, which may or may not cover the costs of these services.
        </Text>
        <Text>
          We will make every effort to <strong>place orders for Excluded Services</strong> in a cost-effective manner for you.
        </Text>

        <Divider />

        {/* Subsection b: Controlled Substances */}
        <Heading as="h3" size="md">
          b. Controlled Substances
        </Heading>
        <Text>
          It is our policy to approach the <strong>prescription of controlled substances</strong> with <strong>extreme care</strong>.
        </Text>
        <Text>
          We will <strong>closely adhere to medical guidelines</strong> for prescribing these medications to ensure safety and compliance.
        </Text>
        <Text>
          If we do prescribe this class of pharmaceuticals for you, you will be required to:
        </Text>
        <VStack align="start" pl={4} spacing={2}>
          <Text>- Sign our <strong>Controlled Substances Agreement</strong></Text>
          <Text>- Honor the terms of the agreement</Text>
        </VStack>
        <Text>
          This ensures your <strong>understanding of the risks and benefits</strong> associated with such treatment.
        </Text>

        <Divider my={4} />
        <Text fontWeight="bold" textAlign="center">
          These exclusions define services and responsibilities that fall outside the scope of this Agreement.
        </Text>
      </VStack>
    
  );
};

export default ExclusionsSection;
