import React from 'react';
import { Box, Text, Heading, Divider, VStack } from '@chakra-ui/react';

const PrivacyAndCommunicationsSection = ({ patientName }) => {
  return (
    
      <VStack align="start" spacing={4}>
        {/* Section 8: Privacy & Communications */}
        <Heading as="h2" size="lg" textAlign="center" mb={4}>
          8. Privacy & Communications
        </Heading>
        <Divider />

        {/* Privacy Rights */}
        <Text>
          <strong>a. Your Privacy Rights:</strong> You acknowledge and hereby authorize <strong>PRACTICE</strong> to gather and maintain medical information, including:
        </Text>
        <VStack align="start" pl={4} spacing={2}>
          <Text>- Test results</Text>
          <Text>- Medical history</Text>
          <Text>- Medical status</Text>
          <Text>- Prescriptions</Text>
          <Text>- Notes of communications</Text>
          <Text>- Other relevant health information</Text>
        </VStack>

        <Text>
          This information will specifically identify you and contain personal medical information protected by <strong>HIPAA regulations</strong> or other applicable laws or regulations.
          Our <strong>PRACTICE</strong> will adhere to its obligations regarding your privacy rights as identified in HIPAA regulations, and we acknowledge our duty to keep your health information private unless that information must be disclosed to prescribe, render, or otherwise undertake treatment of you.
        </Text>

        <Text>
          Your signature on this Agreement attests that you have read, understood, and agree to these specific terms, and further confirms that you have been given a copy of this Agreement digitally or in paper version.
        </Text>

        <Divider />

        {/* Methods of Communication */}
        <Text>
          <strong>b. Methods of Communication:</strong> You acknowledge that <strong>Practice communications</strong> may include:
        </Text>
        <VStack align="start" pl={4} spacing={2}>
          <Text>- Email</Text>
          <Text>- Facsimile</Text>
          <Text>- Text messaging</Text>
          <Text>- Instant messaging</Text>
          <Text>- Cell phone communications</Text>
        </VStack>

        <Text>
          <strong>COMMUNICATIONS</strong> by their nature cannot be guaranteed to be <strong>secure or confidential</strong>. If you initiate a conversation in which you disclose personal information on any of these communication platforms, you authorize <strong>PRACTICE</strong> to communicate with you regarding all protected health information in the same format.
        </Text>

        <Text>
          You will be provided with a <strong>private cellular telephone number</strong> for <strong>OYASU</strong>. Any text message or email received by <strong>OYASU</strong> will receive a response, either in writing or via telephone or video messaging, within <strong>24 hours</strong>. Our goal, however, is to respond to communications from you within <strong>6 hours</strong>.
        </Text>

        <Text>
          Please note that <strong>COMMUNICATION</strong> technology and platforms are wholly outside of our control. Therefore, <strong>OYASU</strong> shall not be liable to you, or anyone, for any cost, damage, expense, injury, or other loss relating to communication malfunction that results in a <strong>delay in response</strong>.
        </Text>

        <Text>
          We kindly ask that you <strong>limit after-hours, weekend, and holiday communication</strong> to urgent situations that cannot wait until the next business day.
        </Text>


      </VStack>
    
  );
};

export default PrivacyAndCommunicationsSection;
